import { notification } from "antd";
import moment from "moment";

export const openNotificationWithIcon = (type, msg, desc) => {
  notification[type]({
    message: msg,
    description: desc,
    placement: "topRight",
  });
};

export const toTimeZone = (time) => {
  const format = "YYYY/MM/DD hh:mm:ss a";
  return moment(time, format).format(format);
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const removeValueFromArray = (arr, val) => {
  const index = arr.indexOf(val);

  if (index > -1) {
    arr.splice(index, 1);
  }

  return arr;
};

export const getWeekStartString = () => {
  const weekStart = getTheDateOfCurrentWeekSunday();
  return `${weekStart.getFullYear()}-${
    weekStart.getMonth() + 1
  }-${weekStart.getDate()}`; // month is zero based
};

export const getTheDateOfCurrentWeekSunday = (day, date) => {
  let sundayInMS = 0;

  let dateInMS = day ? date.getTime() : null;

  switch (day) {
    case "Sun":
      sundayInMS = dateInMS - 0 * 86400000;
      return new Date(sundayInMS);
    case "Mon":
      sundayInMS = dateInMS - 1 * 86400000;
      return new Date(sundayInMS);
    case "Tue":
      sundayInMS = dateInMS - 2 * 86400000;
      return new Date(sundayInMS);
    case "Wed":
      sundayInMS = dateInMS - 3 * 86400000;
      return new Date(sundayInMS);
    case "Thu":
      sundayInMS = dateInMS - 4 * 86400000;
      return new Date(sundayInMS);
    case "Fri":
      sundayInMS = dateInMS - 5 * 86400000;
      return new Date(sundayInMS);
    case "Sat":
      sundayInMS = dateInMS - 6 * 86400000;
      return new Date(sundayInMS);
    default:
      const currentDate = new Date();
      return new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay())
      );
  }
};

export const convertNumToTime = (number) => {
  // Check sign of given number
  let sign = number >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + "";

  // Add padding if need
  if (minute.length < 2) {
    minute = "0" + minute;
  }

  // Add Sign in final result
  sign = sign == 1 ? "" : "-";

  // Concatenate hours and minutes
  const time = sign + hour + ":" + minute;

  return time;
};
