import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
  isAuthenticated: false,
  authUser: null,
}

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false,
    isAuthenticated: true
  });
}
const saveAuthUser = (state, action) => {
  return updateObject(state, {
    authUser: action.payload,
  });
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    isAuthenticated: false
  });
}

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    isAuthenticated: false
  });
}

const authReducer = (state = initialState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.SAVE_AUTH_USER:
      return saveAuthUser(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    default:
      return state;
  }
};

export default authReducer;
