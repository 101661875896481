import {Table, Tag, Space, Popconfirm, Select, Switch} from 'antd';
import moment from "moment";
import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, message} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {destroyUser, getSingleUserDetails, listUsers, updateUser} from "../../store/actions/users";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  StopOutlined
} from "@ant-design/icons";

const {Option} = Select;

const UsersComponent = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const singleUser = useSelector(state => state.usersReducer.singleUser);
  const [singleRecord, setSingleRecord] = useState(singleUser);
  const usersData = props.data

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Financial Force ID',
      dataIndex: 'ffId',
      key: 'ffId',
    },
    {
      title: 'Toggl ID',
      dataIndex: 'togglId',
      key: 'togglId',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Can login?',
      dataIndex: 'canLogin',
      key: 'canLogin',
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => handleEditUser(record._id)}>Edit</a>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => confirm(record._id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setSingleRecord(singleUser);
  }, [singleUser]);

  // for the form
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };
  // end

  const data = usersData.map(record => ({
        ...record,
        key: record._id,
        canLogin: record.canLogin ? <CheckCircleOutlined style={{fontSize: "20px"}}/> :
          <StopOutlined style={{fontSize: "20px"}}/>,
        createdAt: moment(record.createdAt).format('ddd, MMM Do YYYY'),
      })
    )
  ;

  const handleEditUser = (userId) => {
    dispatch(getSingleUserDetails(userId));
    showModal();
  };

  // confirm on delete actions
  function confirm(userId) {
    dispatch(destroyUser(userId));
  }

  function cancel(e) {
    console.log(e);
  }

  // END: confirm on delete actions

  // Actions of Edit modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(updateUser(singleRecord._id, singleRecord));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // END: Actions of Edit modal

  return (
    <>
      <h2>Users</h2>
      <Table columns={columns} dataSource={data}/>

      {/* Edit modal */}
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          initialValues={{
            layout: 'horizontal',
          }}
        >
          <Form.Item label="ِEmail">
            <Input value={singleRecord?.email} onChange={(e) => setSingleRecord(
              {
                ...singleUser,
                email: e.target.value
              }
            )} placeholder="ex: user@swensonhe.com"/>
          </Form.Item>
          <Form.Item label="First name">
            <Input value={singleRecord?.firstName} onChange={(e) => setSingleRecord(
              {
                ...singleUser,
                firstName: e.target.value
              }
            )} placeholder="ex: John"/>
          </Form.Item>
          <Form.Item label="Last name">
            <Input value={singleRecord?.lastName} onChange={(e) => setSingleRecord(
              {
                ...singleUser,
                lastName: e.target.value
              }
            )} placeholder="ex: Duo"/>
          </Form.Item>
          <Form.Item label="Financial Force ID">
            <Input value={singleRecord?.ffId} onChange={(e) => setSingleRecord(
              {
                ...singleUser,
                ffId: e.target.value
              }
            )} placeholder="ex: 49942"/>
          </Form.Item>
          <Form.Item label="Toggl ID">
            <Input value={singleRecord?.togglId} onChange={(e) => setSingleRecord(
              {
                ...singleUser,
                togglId: e.target.value
              }
            )} placeholder="ex: 49942"/>
          </Form.Item>
          <Form.Item label="Role">
            <Select value={singleRecord?.role} style={{width: '100%'}} onChange={v => setSingleRecord({
              ...singleUser,
              role: v,
            })}>
              <Option key={`role-resource`} value="RESOURCE">Resource</Option>
              <Option key={`role-admin`} value="ADMIN">Admin</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Can login?">
            <Switch
              checkedChildren={<CheckOutlined/>}
              unCheckedChildren={<CloseOutlined/>}
              checked={singleRecord?.canLogin}
              onChange={v => setSingleRecord({
                ...singleUser,
                canLogin: v
              })}
            />
          </Form.Item>

        </Form>
      </Modal>
    </>
  )
}

export default UsersComponent;
