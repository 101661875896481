import React from 'react';
import {Layout, Menu, Breadcrumb} from 'antd';
import {UserOutlined, LaptopOutlined, NotificationOutlined} from '@ant-design/icons';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import SideNav from '../components/partials/SideNav';
import TopNav from '../components/partials/TopNav';

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;

class MainLayout extends React.Component {
  state = {
    activeComponent: 'resources',
    hasSideNav: false,
    sideMenu: null,
  };

  adjustSideNav = (activeComponent, hasSideNav, sideMenu) => {
    this.setState({
      activeComponent,
      hasSideNav,
      sideMenu,
    });
  };

  render() {
    let renderedSidenav = (
      <Sider width={220} style={{background: '#fff'}}>
        <SideNav sideMenu={this.state.sideMenu}/>
      </Sider>
    );

    if (window.location.href.includes('perk') && window.location.href.includes('edit')) {
      renderedSidenav = null;
    }

    return (
      <Layout>
        <Header className="header">
          <div className="logo"/>
          <TopNav adjustSideNav={this.adjustSideNav} logout={this.props.logout}/>
        </Header>
        <Layout>
          {this.state.hasSideNav ?
            renderedSidenav
            :
            null
          }

          <Layout style={{padding: '0 24px 24px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  // isAuthenticated: true,
  isAuthenticated: state.authReducer.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
