import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  resources: [],
};

const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RESOURCES:
      return updateObject(state, {
        resources: action.payload,
      });

    default:
      return state;
  }
};

export default resourcesReducer;
