import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  users: [],
  singleUser: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS:
      return updateObject(state, {
        users: action.payload,
      });

    case actionTypes.GET_SINGLE_USER:
      return updateObject(state, {
        singleUser: action.payload,
      });

    default:
      return state;
  }
};

export default usersReducer;
