import axios from "axios";
import {
  ADD_PROJECT,
  DELETE_PROJECT,
  EDIT_PROJECT,
  GET_PROJECTS,
  GET_SINGLE_PROJECT,
} from "./actionTypes";
import { PROJECTS_ENDPOINT } from "../../helpers/endpoints";
import { openNotificationWithIcon } from "../../helpers/helper_functions";
import { HOST } from "../../helpers/constants";

export const getProjects = (projects) => ({
  type: GET_PROJECTS,
  payload: projects,
});

export const getSingleProject = (project) => ({
  type: GET_SINGLE_PROJECT,
  payload: project,
});

export const editProject = (project) => ({
  type: EDIT_PROJECT,
  payload: project,
});

export const deleteProject = (id) => ({
  type: DELETE_PROJECT,
  payload: id,
});

export const addProject = (project) => ({
  type: ADD_PROJECT,
  payload: project,
});

export const listProjects = () => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + PROJECTS_ENDPOINT}`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getProjects(res.data.data));
    })
    .catch((err) => console.log(err));
};

export const getSingleProjectDetails = (id) => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + PROJECTS_ENDPOINT}/${id}`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      dispatch(getSingleProject(res.data));
    })
    .catch((err) => console.log(err));
};

export const updateProject = (projectId, projectData) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + PROJECTS_ENDPOINT}/${projectId}`;

  return (dispatch) => {
    axios
      .patch(URL, projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Host: `${HOST}`,
          Accept: "*/*",
        },
      })
      .then((res) => {
        openNotificationWithIcon("success", "Succeeded", "Project updated!");
        dispatch(editProject(res.data));
        dispatch(listProjects());
      })
      .catch((err) => console.log(err));
  };
};

export const destroyProject = (projectId) => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + PROJECTS_ENDPOINT}/${projectId}`;

  axios
    .delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      dispatch(deleteProject(projectId));
      console.log(res);
      dispatch(listProjects());
      openNotificationWithIcon("success", "Succeeded", "Project deleted");
    })
    .catch((err) => console.log(err));
};

export const createProject = (projectData) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + PROJECTS_ENDPOINT}`;

  return (dispatch) => {
    axios
      .post(URL, projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Host: `${HOST}`,
          Accept: "*/*",
        },
      })
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Succeeded",
          "New project created!"
        );
        dispatch(addProject(res.data));
        dispatch(listProjects());
      })
      .catch((err) => {
        console.log(err);
        openNotificationWithIcon(
          "error",
          "Failed",
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Project creation failed"
        );
      });
  };
};
