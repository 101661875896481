import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  allocations: [],
  singleAllocation: null,
};

const allocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALLOCATIONS:
      return updateObject(state, {
        allocations: action.payload,
      });

    case actionTypes.GET_SINGLE_ALLOCATION:
      return updateObject(state, {
        singleAllocation: action.payload,
      });

    case actionTypes.DELETE_ALLOCATION:
      const allocations = state.allocations.filter(
        (allocation) => allocation._id !== action.payload
      );
      return updateObject(state, {
        allocations,
      });

    default:
      return state;
  }
};

export default allocationsReducer;
