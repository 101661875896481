import React from 'react';
import RouteWithLayout from './helpers/RouteWithLayout';
import MainLayout from './containers/MainLayout';
import LoginLayout from './containers/LoginLayout';
import PrivateRouter from './helpers/PrivateRoute';
import Login from './components/auth/Login';
import Resources from './components/resources/Resources';
import Projects from "./components/projects/Projects";
import CreateProject from "./components/projects/CreateProject";
import Allocations from "./components/allocations/Allocations";
import CreateUser from "./components/users/CreateUser";
import Users from "./components/users/Users";

const BaseRouter = (props) => (
  <div>
    <RouteWithLayout exact path='/' component={Login} layout={LoginLayout} isAuthenticated={props.isAuthenticated} />
    <RouteWithLayout
      exact path='/login/' component={Login} layout={LoginLayout}
      isAuthenticated={props.isAuthenticated}
    />

    <PrivateRouter
      exact path='/resources/' component={Resources} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/projects/' component={Projects} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/projects/list/' component={Projects} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/projects/create/' component={CreateProject} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/allocations/' component={Allocations} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/allocations/list/' component={Allocations} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/users/' component={Users} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/users/list/' component={Users} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />
    <PrivateRouter
      exact path='/users/create/' component={CreateUser} layout={MainLayout}
      isAuthenticated={props.isAuthenticated}
    />

  </div>
);

export default BaseRouter;
