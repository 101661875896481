import React from 'react';
import {Layout} from 'antd';
import background from './login_wp.jpg';

const {Content, Footer} = Layout;

const LoginLayout = (props) => (
  <Layout className="layout" style={{backgroundImage: `url(${background})`, backgroundSize: "100% 100%", height: '100vh'}}>
    {/*<div style={{backgroundImage: background}}>*/}
      <Content style={{padding: '50px', width: '500px', margin: 'auto', marginTop: '13%'}}>
        <div style={{backgroundColor: '#fff', opacity: 0.95, padding: 24, minHeight: 260}}>
          {props.children}
        </div>
      </Content>
      <Footer style={{textAlign: 'center'}}>
        SH-PTA dashboard ©{new Date().getFullYear()} - All copyrights are reserved to @SwensonHe.
      </Footer>
    {/*</div>*/}
  </Layout>
);

export default LoginLayout;

