import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
import 'antd/dist/antd.css';
import BaseRouter from './routes';
import * as actions from './store/actions/auth';

import MainLayout from './containers/MainLayout';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  async componentDidMount() {
    await this.props.onTryAutoSignin();
    this.setState({ loaded: true });
  }

  content() {
    return (
      <div className="App">
        <Router>
          {this.props.isAuthenticated
            ?
            <MainLayout>
              <BaseRouter isAuthenticated={this.props.isAuthenticated}/>
            </MainLayout>
            :
            <BaseRouter isAuthenticated={this.props.isAuthenticated}/>
          }
        </Router>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.loaded ? this.content() : <span>Loading ... </span>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  onTryAutoSignin: () => dispatch(actions.authCheckState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
