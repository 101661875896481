import { combineReducers } from "redux";
import authReducer from "./auth";
import resourcesReducer from "./resources";
import projectsReducer from "./projects";
import usersReducer from "./users";
import allocationsReducer from "./allocations";

export default combineReducers({
  authReducer,
  resourcesReducer,
  projectsReducer,
  allocationsReducer,
  usersReducer,
  // emailTemplatesReducer,
  // perksReducer,
  // eventsReducer,
  // metricsReducer,
  // collapsedFiltersReducer,
});
