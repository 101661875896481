import {
  Table,
  Space,
  Popconfirm,
  DatePicker,
  Select,
  Row,
  Col,
  Button,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createAllocation,
  destroyAllocation,
  getSingleAllocationDetails,
  updateAllocation,
} from "../../store/actions/allocations";
import WeekSelector from "../partials/WeekSelector";

const { Title } = Typography;
const { Option } = Select;

const AllocationsComponent = (props) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const singleAllocation = useSelector(
    (state) => state.allocationsReducer.singleAllocation
  );
  const [singleRecord, setSingleRecord] = useState(singleAllocation);
  const [newRecord, setNewRecord] = useState({
    allocatedHours: 10,
  });
  const allocationsData = props.data;

  const allProjects = useSelector((state) => state.projectsReducer.projects);
  const allTogglResources = useSelector(
    (state) => state.resourcesReducer.resources
  );
  const allUsers = useSelector((state) => state.usersReducer.users);

  const columns = [
    {
      title: "Start date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Allocated hours",
      dataIndex: "allocatedHours",
      key: "allocatedHours",
    },
    {
      title: "Project",
      key: "project",
      dataIndex: "project",
    },
    {
      title: "Resource",
      key: "resource",
      dataIndex: "resource",
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => handleEditAllocation(record._id)}>Edit</a>
          <Popconfirm
            title="Are you sure to delete this allocation?"
            onConfirm={() => confirm(record._id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setSingleRecord(
      singleAllocation
        ? {
            ...singleAllocation,
          }
        : null
    );
  }, [singleAllocation]);

  // for the form
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };
  // end

  const data = allocationsData.map((record) => ({
    ...record,
    key: record._id,
    project: record.project.name,
    resource: `${record.resource.firstName} ${record.resource.lastName}`,
    startDate: moment(record.startDate).format("ddd, MMM Do YYYY"),
    endDate: moment(record.endDate).format("ddd, MMM Do YYYY"),
    createdAt: moment(record.createdAt).format("ddd, MMM Do YYYY"),
  }));

  const handleEditAllocation = (allocationId) => {
    dispatch(getSingleAllocationDetails(allocationId));
    showEditModal();
  };

  // date picker on change
  function onChangeStartDate(date, dateString) {
    setSingleRecord({
      ...singleRecord,
      startDate: dateString,
    });
  }

  function onChangeEndDate(date, dateString) {
    setSingleRecord({
      ...singleRecord,
      endDate: dateString,
    });
  }

  function onChangeWeekDateAddAllocation(startDate, endDate) {
    setNewRecord({
      ...newRecord,
      startDate,
      endDate,
    });
  }

  // END: date picker on change

  // confirm on delete actions
  function confirm(allocationId) {
    dispatch(destroyAllocation(allocationId));
  }

  function cancel(e) {
    console.log(e);
  }

  // END: confirm on delete actions

  // Actions of Edit modal
  const showEditModal = () => {
    setIsEditModalVisible(true);
  };

  const handleEditOk = () => {
    dispatch(updateAllocation(singleRecord._id, singleRecord));
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };
  // END: Actions of Edit modal

  // Actions of Add modal
  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddOk = () => {
    dispatch(createAllocation(newRecord));
    setIsAddModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };
  // END: Actions of Add modal

  return (
    <>
      <Row>
        <Col span={21}>
          <Title level={3}>Allocations</Title>
        </Col>
        <Col span={2}>
          <Button onClick={showAddModal} size="large" type="primary">
            Add allocation
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Table columns={columns} dataSource={data} />
        </Col>
      </Row>

      {/* Edit allocation modal */}
      <Modal
        title="Edit allocation"
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      >
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
        >
          <Form.Item label="Start date">
            <DatePicker
              value={moment(singleRecord?.startDate)}
              onChange={onChangeStartDate}
            />
          </Form.Item>
          <Form.Item label="End date">
            <DatePicker
              value={moment(singleRecord?.endDate)}
              onChange={onChangeEndDate}
            />
          </Form.Item>
          <Form.Item label="Allocated hours">
            <Input
              type="number"
              value={singleRecord?.allocatedHours}
              onChange={(e) =>
                setSingleRecord({
                  ...singleRecord,
                  allocatedHours: e.target.value,
                })
              }
              defaultValue={20}
            />
          </Form.Item>
          <Form.Item label="Project">
            <Select
              style={{ width: "100%" }}
              value={singleRecord?.projectId}
              onChange={(v) =>
                setSingleRecord({
                  ...singleRecord,
                  projectId: v,
                })
              }
            >
              {allProjects.map((project) => (
                <Option key={`project-${project._id}`} value={project._id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Resource">
            <Select
              value={singleRecord?.resourceId}
              style={{ width: "100%" }}
              onChange={(v) =>
                setSingleRecord({
                  ...singleRecord,
                  resourceId: v,
                })
              }
            >
              {allUsers.map((user) => (
                <Option
                  key={`user-${user._id}`}
                  value={user._id}
                >{`${user.firstName} ${user.lastName}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {/* END: Edit modal */}

      {/* Add allocation modal */}
      <Modal
        title="Add new allocation"
        visible={isAddModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
      >
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
        >
          <Form.Item label="Start date">
            <WeekSelector action={onChangeWeekDateAddAllocation}></WeekSelector>
          </Form.Item>
          <Form.Item label="Allocated hours">
            <Input
              defaultValue={10}
              type="number"
              onChange={(e) =>
                setNewRecord({
                  ...newRecord,
                  allocatedHours: parseInt(e.target.value),
                })
              }
            />
          </Form.Item>
          <Form.Item label="Project">
            <Select
              style={{ width: "100%" }}
              onChange={(v) =>
                setNewRecord({
                  ...newRecord,
                  projectId: v,
                })
              }
            >
              {allProjects.map((project) => (
                <Option key={`project-${project._id}`} value={project._id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Resource">
            <Select
              style={{ width: "100%" }}
              onChange={(v) =>
                setNewRecord({
                  ...newRecord,
                  resourceId: v,
                })
              }
            >
              {allUsers.map((user) => (
                <Option
                  key={`user-${user._id}`}
                  value={user._id}
                >{`${user.firstName} ${user.lastName}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {/* END: Add modal */}
    </>
  );
};

export default AllocationsComponent;
