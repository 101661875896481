import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  projects: [],
  singleProject: null,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROJECTS:
      return updateObject(state, {
        projects: action.payload,
      });

    case actionTypes.GET_SINGLE_PROJECT:
      return updateObject(state, {
        singleProject: action.payload,
      });

    default:
      return state;
  }
};

export default projectsReducer;
