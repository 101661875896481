import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { NavLink, withRouter } from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";

const { SubMenu } = Menu;

const TopNav = ({ logout, adjustSideNav }) => {
  const [loaded, setLoaded] = useState(false);
  const route = useLocation();
  const regex = new RegExp("/", "gi");
  const path = route.pathname.replace(regex, "");

  useEffect(() => {
    setLoaded(true);
  }, []);

  const sideNavData = {
    resources: {
      componentName: "resources",
      hasSideNav: false,
      sideMenu: null,
    },
    projects: {
      componentName: "projects",
      hasSideNav: true,
      sideMenu: (
        <SubMenu
          key="sub1"
          title={
            <span>
              <UserOutlined />
              <span> Projects </span>
            </span>
          }
        >
          <Menu.Item key="1">
            <NavLink to="/projects/list/">All Projects</NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <NavLink to="/projects/create/">New Project</NavLink>
          </Menu.Item>
        </SubMenu>
      ),
    },
    allocations: {
      componentName: "allocations",
      hasSideNav: false,
      sideMenu: null,
    },
    users: {
      componentName: "users",
      hasSideNav: true,
      sideMenu: (
        <SubMenu
          key="sub1"
          title={
            <span>
              <UserOutlined />
              <span> Users </span>
            </span>
          }
        >
          <Menu.Item key="1">
            <NavLink to="/users/list/">All Users</NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <NavLink to="/users/create/">New User</NavLink>
          </Menu.Item>
        </SubMenu>
      ),
    },
  };

  const content = () => {
    const menu = (
      <Menu>
        {/*<Menu.Item key="p0">*/}
        {/* <NavLink to="/my_profile/">my account</NavLink>*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Divider/>*/}
        <Menu.Item key="p3" onClick={logout}>
          logout
        </Menu.Item>
      </Menu>
    );

    return (
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[path]}
        style={{ lineHeight: "64px" }}
      >
        <Menu.Item
          key="resources"
          onClick={() => {
            adjustSideNav(
              sideNavData.resources.componentName,
              sideNavData.resources.hasSideNav,
              sideNavData.resources.sideMenu
            );
          }}
        >
          <NavLink to="/resources/">Resources</NavLink>
        </Menu.Item>
        <Menu.Item
          key="projects"
          onClick={() => {
            adjustSideNav(
              sideNavData.projects.componentName,
              sideNavData.projects.hasSideNav,
              sideNavData.projects.sideMenu
            );
          }}
        >
          <NavLink to="/projects/">Projects</NavLink>
        </Menu.Item>
        <Menu.Item
          key="allocations"
          onClick={() => {
            adjustSideNav(
              sideNavData.allocations.componentName,
              sideNavData.allocations.hasSideNav,
              sideNavData.allocations.sideMenu
            );
          }}
        >
          <NavLink to="/allocations/">Allocations</NavLink>
        </Menu.Item>
        <Menu.Item
          key="users"
          onClick={() => {
            adjustSideNav(
              sideNavData.users.componentName,
              sideNavData.users.hasSideNav,
              sideNavData.users.sideMenu
            );
          }}
        >
          <NavLink to="/users/">Users</NavLink>
        </Menu.Item>

        <Menu.Item key="9" style={{ float: "right" }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <UserOutlined />
          </Dropdown>
        </Menu.Item>
      </Menu>
    );
  };

  return <div>{loaded ? content() : null}</div>;
};

export default withRouter(TopNav);
