export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const SAVE_AUTH_USER = "SAVE_AUTH_USER";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const GET_RESOURCES = "GET_RESOURCES";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_SINGLE_PROJECT = "GET_SINGLE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";

export const GET_USERS = "GET_USERS";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";

export const GET_ALLOCATIONS = "GET_ALLOCATIONS";
export const GET_SINGLE_ALLOCATION = "GET_SINGLE_ALLOCATION";
export const EDIT_ALLOCATION = "EDIT_ALLOCATION";
export const DELETE_ALLOCATION = "DELETE_ALLOCATION";
export const ADD_ALLOCATION = "ADD_ALLOCATION";
