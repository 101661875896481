// ======== Development host ========
// export const HOST = "http://localhost:3030";
// export const DOMAIN = "http://localhost:3030";
// export const ENV = "development";
// ======== Production host ========
// export const HOST = 'https://pla_pla_pla.com/api';

// ======== Local ========
// export const HOST = 'http://localhost:3030';
// export const DOMAIN = 'localhost:3030';

// ======== AWS-DEV ========
export const HOST =
  "https://w0iuxhjs4f.execute-api.us-west-2.amazonaws.com/dev";
export const DOMAIN = "w0iuxhjs4f.execute-api.us-west-2.amazonaws.com/dev";
export const ENV = "production";

export const USER_AGENT = "pta@swensonhe.com";
export const TOGGL_WORKSPACE_ID = "3371545";
export const TOGGL_REPORT_API_BASE_URL =
  "https://api.track.toggl.com/reports/api/v2";
export const GROUPING_BY_USERS = "grouping=users";
export const GROUPING_BY_PROJECTS = "grouping=projects";
