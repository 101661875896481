import UsersComponent from "./UsersComponent";
import {useSelector, useDispatch} from "react-redux";
import {useEffect} from "react";
import {listUsers} from "../../store/actions/users";

const Users = (props) => {
  const users = useSelector(state => state.usersReducer.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listUsers());
  }, []);

  return (
    <>
      <UsersComponent data={users}/>
    </>
  )
}

export default Users;
