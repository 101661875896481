import axios from 'axios';
import * as actionTypes from './actionTypes';
import {DOMAIN, HOST} from '../../helpers/constants';
import {LOGIN_ENDPOINT} from '../../helpers/endpoints';
import {openNotificationWithIcon} from '../../helpers/helper_functions';

export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authSuccess = token => ({
  type: actionTypes.AUTH_SUCCESS,
  token,
});

export const saveAuthUser = user => ({
  type: actionTypes.SAVE_AUTH_USER,
  payload: user,
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = expirationDate => dispatch => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationDate * 1000);
};

export const authLogin = (email, password) => dispatch => {
  dispatch(authStart());
  axios.post(HOST + LOGIN_ENDPOINT, {
    email,
    password,
    strategy: "local"
  }, {
    headers: {
      'X-Forwarded-Host': DOMAIN,
      Accept: 'application/json',
    },
  }).then(res => {
    const token = res.data.accessToken;
    const expirationDate = new Date(new Date().getTime() + (24 * 60 * 60 * 1000)); // 30 days to expire the token

    // get user full name
    const name = res.data.user.firstName;
    const authUser = res.data.user;

    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate);

    dispatch(authSuccess(token));
    dispatch(saveAuthUser(authUser));
    openNotificationWithIcon('success', 'Successfully logged in', `Welcome, ${name}`);
    dispatch(checkAuthTimeout(3600));
  }).catch(err => {
    openNotificationWithIcon('error', 'Login failed', 'Invalid credential, wrong email or password!');
    dispatch(authFail(err));
  });
};

export const authCheckState = () => dispatch => {
  const token = localStorage.getItem('token');
  if (token === undefined) {
    dispatch(logout());
  } else {
    const expirationDate = new Date(localStorage.getItem('expirationDate'));
    if (expirationDate <= new Date()) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token));
    }
  }
};
