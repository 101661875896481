import { Table, Input } from "antd";
import { convertNumToTime } from "../../helpers/helper_functions";

const allocationRenderer = (text, record, index) => {
  return <span>{text}</span>;
  // return (
  //   <Input
  //     style={{ width: "5em" }}
  //     defaultValue={text}
  //     onBlur={(e) => {
  //       const newTime = e.target.value;
  //       if (newTime !== text) {
  //         console.log({ newTime });
  //       }
  //     }}
  //   />
  // );
};

const getRowData = (projects, togglLoggedHours, projectRelatedAllocation) => {
  const data = [];
  for (let i = 0; i < projects.length; ++i) {
    let projectAlloc = 0;
    let allocationId = null;
    const projectTogglId = projects[i].togglId;

    const project = projectRelatedAllocation.filter(
      (alloc) => alloc.project.togglId == projectTogglId
    );
    const loggedData = togglLoggedHours.filter((logged) => {
      console.log(logged);
      return logged.pid == projectTogglId;
    });

    if (project[0]) {
      projectAlloc = project[0].allocatedHours;
      allocationId = project[0]._id;
    }
    const currentTogglData = loggedData[0];
    if (projectAlloc > 0 || (currentTogglData && currentTogglData.totals[7])) {
      data.push({
        key: i,
        allocationId,
        client: (
          <p
            style={{
              color: `${
                currentTogglData ? currentTogglData.title.hex_color : "gray"
              }`,
            }}
          >
            {currentTogglData
              ? currentTogglData.title.client
              : projects[i].name}
          </p>
        ),
        project: (
          <p
            style={{
              color: `${
                currentTogglData ? currentTogglData.title.hex_color : "gray"
              }`,
            }}
          >
            {projects[i].name}
          </p>
        ),
        logged_hrs: currentTogglData
          ? convertNumToTime(currentTogglData.totals[7] / 1000 / 60 / 60)
          : 0,
        project_allocation: `${projectAlloc}:00`,
        remaining_hours: currentTogglData
          ? convertNumToTime(
              (projectAlloc * 60 * 60 * 1000 - currentTogglData.totals[7]) /
                1000 /
                60 /
                60
            )
          : projectAlloc,
      });
    }
  }
  return data;
};

const ResourcesComponent = (props) => {
  const resourcesData = props.data;
  const allocationsData = props.allocations;
  const { users, projects } = props;

  const expandedRowRender = (row) => {
    const columns = [
      { title: "Client", dataIndex: "client", key: "client" },
      { title: "Project", dataIndex: "project", key: "project" },
      {
        title: "Allocation",
        dataIndex: "project_allocation",
        key: "project_allocation",
        render: allocationRenderer,
      },
      { title: "Logged Hrs", dataIndex: "logged_hrs", key: "logged_hrs" },
      {
        title: "Remaining Hrs",
        dataIndex: "remaining_hours",
        key: "remaining_hours",
      },
    ];

    const matchedData = resourcesData.filter(
      (item) => item.uid === row.togglId
    );
    const togglLoggedHours = matchedData[0] ? matchedData[0].details : [];

    const projectRelatedAllocation = allocationsData.filter((alloc) => {
      return alloc.resource && alloc.resource._id == row.userId;
    });
    const rowData = getRowData(
      projects,
      togglLoggedHours,
      projectRelatedAllocation
    );

    return <Table columns={columns} dataSource={rowData} pagination={false} />;
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Total Allocations",
      dataIndex: "total_allocations",
      key: "total_allocations",
    },
    {
      title: "Logged Hours",
      dataIndex: "total_hrs",
      key: "total_hrs",
    },
    {
      title: "Remaining Hours",
      dataIndex: "remaining_hours",
      key: "remaining_hours",
    },
    //TODO: { title: "Action", key: "operation", render: () => <a>Add allocation</a> },
  ];

  const data = [];
  for (let i = 0; i < users.length; i++) {
    const resourceRelatedAllocation = allocationsData.filter(
      (alloc) => alloc.resource && alloc.resource.togglId == users[i].togglId
    );
    const allocatedHoursArr = resourceRelatedAllocation.map(
      (alloc) => alloc.allocatedHours
    );
    const resourceTotalAllocation = allocatedHoursArr.reduce(
      (a, b) => a + b,
      0
    );
    const togglData = resourcesData.filter((entry) => {
      return entry.uid == users[i].togglId;
    });

    data.push({
      key: i,
      userId: users[i]._id,
      togglId: togglData[0] ? togglData[0].uid : null,
      name: `${users[i].firstName} ${users[i].lastName}`,
      total_allocations: `${resourceTotalAllocation}:00`,
      total_hrs: togglData[0]
        ? convertNumToTime(togglData[0].totals[7] / 1000 / 60 / 60)
        : 0,
      remaining_hours: togglData[0]
        ? convertNumToTime(
            (resourceTotalAllocation * 60 * 60 * 1000 -
              togglData[0].totals[7]) /
              60 /
              60 /
              1000
          )
        : 0,
    });
  }

  return (
    <Table
      className="components-table-demo-nested"
      columns={columns}
      expandedRowRender={expandedRowRender}
      dataSource={data}
    />
  );
};

export default ResourcesComponent;
