import axios from "axios";
import { GET_RESOURCES } from "./actionTypes";
import { WEEKLY_REPORT_ENDPOINT } from "../../helpers/endpoints";
import { getWeekStartString } from "../../helpers/helper_functions";
import {
  TOGGL_REPORT_API_BASE_URL,
  TOGGL_WORKSPACE_ID,
  USER_AGENT,
} from "../../helpers/constants";

export const getResources = (resources) => ({
  type: GET_RESOURCES,
  payload: resources,
});

export const listResources = (weekStart) => (dispatch) => {
  if (!weekStart) {
    weekStart = getWeekStartString();
  }
  const URL = `${
    TOGGL_REPORT_API_BASE_URL + WEEKLY_REPORT_ENDPOINT
  }?user_agent=${USER_AGENT}&workspace_id=${TOGGL_WORKSPACE_ID}&since=${weekStart}&grouping=users`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Basic ODdiNjU0NmFkZWVmMDhjNjJmOWQxZjc0MDY0OGY1N2U6YXBpX3Rva2Vu`,
        // Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getResources(res.data.data));
    })
    .catch((err) => console.log(err));
};
