import axios from "axios";
import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  GET_SINGLE_USER,
} from "./actionTypes";
import { USERS_ENDPOINT } from "../../helpers/endpoints";
import { openNotificationWithIcon } from "../../helpers/helper_functions";
import { HOST } from "../../helpers/constants";

export const getUsers = (users) => ({
  type: GET_USERS,
  payload: users,
});

export const getSingleUser = (user) => ({
  type: GET_SINGLE_USER,
  payload: user,
});

export const editUser = (user) => ({
  type: EDIT_USER,
  payload: user,
});

export const deleteUser = (id) => ({
  type: DELETE_USER,
  payload: id,
});

export const addUser = (user) => ({
  type: ADD_USER,
  payload: user,
});

export const listUsers = () => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + USERS_ENDPOINT}`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getUsers(res.data.data));
    })
    .catch((err) => console.log(err));
};

export const getSingleUserDetails = (id) => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + USERS_ENDPOINT}/${id}`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      dispatch(getSingleUser(res.data));
    })
    .catch((err) => console.log(err));
};

export const updateUser = (userId, userData) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + USERS_ENDPOINT}/${userId}`;

  return (dispatch) => {
    axios
      .patch(URL, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Host: `${HOST}`,
          Accept: "*/*",
        },
      })
      .then((res) => {
        openNotificationWithIcon("success", "Succeeded", "User updated!");
        dispatch(editUser(res.data));
        dispatch(listUsers());
      })
      .catch((err) => console.log(err));
  };
};

export const destroyUser = (userId) => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + USERS_ENDPOINT}/${userId}`;

  axios
    .delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      dispatch(deleteUser(userId));
      console.log(res);
      dispatch(listUsers());
      openNotificationWithIcon("success", "Succeeded", "User deleted");
    })
    .catch((err) => console.log(err));
};

export const createUser = (userData) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + USERS_ENDPOINT}`;

  return (dispatch) => {
    axios
      .post(URL, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Host: `${HOST}`,
          Accept: "*/*",
        },
      })
      .then((res) => {
        openNotificationWithIcon("success", "Succeeded", "New user created!");
        dispatch(addUser(res.data));
        dispatch(listUsers());
      })
      .catch((err) => console.log(err));
  };
};
