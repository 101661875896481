import moment from "moment";
import { DatePicker } from "antd";
import { getTheDateOfCurrentWeekSunday } from "../../helpers/helper_functions";
import { useEffect } from "react";

const WeekSelector = ({ action }) => {
  useEffect(() => {
    // setting default value
    const startDate = getTheDateOfCurrentWeekSunday();
    const endDate = new Date(
      getTheDateOfCurrentWeekSunday().getTime() + 7 * 86400000
    );
    action(
      moment(startDate).format("yyyy-MM-DD"),
      moment(endDate).format("yyyy-MM-DD")
    );
  }, []);

  const onChangeWeekDateAddAllocation = (date) => {
    if (!date) {
      return;
    }
    const day = date._d.toString().substr(0, 3);
    const dateObj = date._d;
    const startDate = getTheDateOfCurrentWeekSunday(day, dateObj);
    const endDate = new Date(
      getTheDateOfCurrentWeekSunday(day, dateObj).getTime() + 7 * 86400000
    );

    action(
      moment(startDate).format("yyyy-MM-DD"),
      moment(endDate).format("yyyy-MM-DD")
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "space-around",
        alignItems: "baseline",
      }}
    >
      <h2>Period:&nbsp;&nbsp;&nbsp;</h2>
      <DatePicker
        defaultValue={moment(getTheDateOfCurrentWeekSunday())}
        picker="week"
        onChange={onChangeWeekDateAddAllocation}
      />
    </div>
  );
};

export default WeekSelector;
