import {Table, Tag, Space, Popconfirm} from 'antd';
import moment from "moment";
import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, message} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {destroyProject, getSingleProjectDetails, listProjects, updateProject} from "../../store/actions/projects";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const ProjectsComponent = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const singleProject = useSelector(state => state.projectsReducer.singleProject);
  const [singleRecord, setSingleRecord] = useState(singleProject);
  const projectsData = props.data

  const columns = [
    {
      title: 'Financial Force ID',
      dataIndex: 'ffId',
      key: 'ffId',
    },
    {
      title: 'Account FF ID',
      dataIndex: 'accountFfId',
      key: 'accountFfId',
    },
    {
      title: 'Toggl ID',
      dataIndex: 'togglId',
      key: 'togglId',
    },
    {
      title: 'Account Toggl ID',
      key: 'accountTogglId',
      dataIndex: 'accountTogglId',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => handleEditProject(record._id)}>Edit</a>
          <Popconfirm
            title="Are you sure to delete this project?"
            onConfirm={() => confirm(record._id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setSingleRecord(singleProject);
  }, [singleProject]);

  // for the form
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };
  // end

  const data = projectsData.map(record => ({
      ...record,
      key: record._id,
      createdAt: moment(record.createdAt).format('ddd, MMM Do YYYY'),
    })
  );

  const handleEditProject = (projectId) => {
    dispatch(getSingleProjectDetails(projectId));
    showModal();
  };

  // confirm on delete actions
  function confirm(projectId) {
    dispatch(destroyProject(projectId));
  }

  function cancel(e) {
    console.log(e);
  }

  // END: confirm on delete actions

  // Actions of Edit modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(updateProject(singleRecord._id, singleRecord));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // END: Actions of Edit modal

  return (
    <>
      <h2>Projects</h2>
      <Table columns={columns} dataSource={data}/>

      {/* Edit modal */}
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          initialValues={{
            layout: 'horizontal',
          }}
        >

          <Form.Item label="Financial Force ID">
            <Input value={singleRecord?.ffId} onChange={(e) => setSingleRecord(
              {
                ...singleProject,
                ffId: e.target.value
              }
            )} placeholder="ex: 49942"/>
          </Form.Item>
          <Form.Item label="Account FF ID">
            <Input value={singleRecord?.accountFfId} onChange={(e) => setSingleRecord(
              {
                ...singleProject,
                accountFfId: e.target.value
              }
            )} placeholder="ex: 554400"/>
          </Form.Item>
          <Form.Item label="Toggl ID">
            <Input value={singleRecord?.togglId} onChange={(e) => setSingleRecord(
              {
                ...singleProject,
                togglId: e.target.value
              }
            )} placeholder="ex: 49942"/>
          </Form.Item>
          <Form.Item label="Account Toggl ID">
            <Input value={singleRecord?.accountTogglId} onChange={(e) => setSingleRecord(
              {
                ...singleProject,
                accountTogglId: e.target.value
              }
            )} placeholder="ex: 554400"/>
          </Form.Item>
          <Form.Item label="Project name">
            <Input value={singleRecord?.name} onChange={(e) => setSingleRecord(
              {
                ...singleProject,
                name: e.target.value
              }
            )} placeholder="ex: X Project"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ProjectsComponent;
