import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, Layout, Row } from "antd";
import React, { useState } from "react";
import { createProject } from "../../store/actions/projects";

const CreateProject = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [newProject, setNewProject] = useState({});

  // for the form
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 10,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 10,
      offset: 4,
    },
  };
  // end

  const handleCreateProject = () => {
    dispatch(createProject(newProject));
  };

  return (
    <>
      <h2>Create new project</h2>

      <Row>
        <Col span={16}>
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            initialValues={{
              layout: "horizontal",
            }}
          >
            <Form.Item label="Project name*">
              <Input
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    name: e.target.value,
                  })
                }
                placeholder="ex: X Project"
              />
            </Form.Item>
            <Form.Item label="Toggl ID*">
              <Input
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    togglId: e.target.value,
                  })
                }
                placeholder="ex: 49942"
              />
            </Form.Item>
            <Form.Item label="Account Toggl ID*">
              <Input
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    accountTogglId: e.target.value,
                  })
                }
                placeholder="ex: 554400"
              />
            </Form.Item>
            <Form.Item label="Financial Force ID">
              <Input
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    ffId: e.target.value,
                  })
                }
                placeholder="ex: 49942"
              />
            </Form.Item>
            <Form.Item label="Account FF ID">
              <Input
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    accountFfId: e.target.value,
                  })
                }
                placeholder="ex: 554400"
              />
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
              <Button onClick={handleCreateProject} type="primary">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateProject;
