import React from 'react';
import {Menu} from 'antd';


class SideNav extends React.Component {
  handleClick = e => {
    console.log('click ', e);
  };

  render() {

    const {sideMenu} = this.props;

    return (
      <Menu
        onClick={this.handleClick}
        style={{width: 220, height: '100%', borderRight: 0}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
      >
        {sideMenu}
      </Menu>
    );
  }
}

export default SideNav;