import React, {Fragment} from 'react';
import {Form, Input, Button, Checkbox, Spin, Image} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/auth';
import logo from './Swenson-He-logo.jpeg';

class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props);

    if (props.isAuthenticated) {
      // alert("You can't login if you are logged in!");
      props.history.push('/resources/');
    }
  }

  handleSubmit = values => {
    this.props.onAuth(values.username, values.password);
  };

  render() {
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = (
        <p>{this.props.error.message}</p>
      );
    }

    return (
      <div style={{background: '#ffffff'}}>
        {errorMessage}
        {
          this.props.loading ?
            <Spin/>
            :
            <Fragment>
              <div style={{textAlign: "center"}}>
                <img src={logo}/>
                <br/><br/>
              </div>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{remember: true}}
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  name="username"
                  rules={[{required: true, message: 'Please input your Username!'}]}
                >
                  <Input
                    prefix={<UserOutlined/>}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{required: true, message: 'Please input your Password!'}]}
                >
                  <Input
                    prefix={<LockOutlined/>}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  error: state.error,
  isAuthenticated: state.authReducer.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  onAuth: (username, password) => dispatch(actions.authLogin(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);
