import {useDispatch} from "react-redux";
import {Button, Col, Form, Input, Layout, Row, Select, Switch} from "antd";
import React, {useState} from "react";
import {createUser} from "../../store/actions/users";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const {Option} = Select;

const CreateUser = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [newUser, setNewUser] = useState({
    canLogin: true
  });

  // for the form
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 10,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 10,
      offset: 4,
    },
  };
  // end

  const handleCreateUser = () => {
    dispatch(createUser(newUser));
  }

  return (
    <>
      <h2>Create new user</h2>

      <Row>
        <Col span={16}>
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            initialValues={{
              layout: 'horizontal',
            }}
          >
            <Form.Item label="ِEmail">
              <Input onChange={(e) => setNewUser(
                {
                  ...newUser,
                  email: e.target.value
                }
              )} placeholder="ex: user@swensonhe.com"/>
            </Form.Item>
            <Form.Item label="First name">
              <Input onChange={(e) => setNewUser(
                {
                  ...newUser,
                  firstName: e.target.value
                }
              )} placeholder="ex: John"/>
            </Form.Item>
            <Form.Item label="Last name">
              <Input onChange={(e) => setNewUser(
                {
                  ...newUser,
                  lastName: e.target.value
                }
              )} placeholder="ex: Duo"/>
            </Form.Item>
            <Form.Item label="Financial Force ID">
              <Input onChange={(e) => setNewUser(
                {
                  ...newUser,
                  ffId: e.target.value
                }
              )} placeholder="ex: 49942"/>
            </Form.Item>
            <Form.Item label="Toggl ID">
              <Input onChange={(e) => setNewUser(
                {
                  ...newUser,
                  togglId: e.target.value
                }
              )} placeholder="ex: 49942"/>
            </Form.Item>
            <Form.Item label="Role">
              <Select style={{width: '100%'}} onChange={v => setNewUser({
                ...newUser,
                role: v,
              })}>
                <Option key={`role-resource`} value="RESOURCE">Resource</Option>
                <Option key={`role-admin`} value="ADMIN">Admin</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Can login?">
              <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                defaultChecked
                onChange={v => setNewUser({
                  ...newUser,
                  canLogin: v
                })}
              />
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
              <Button onClick={handleCreateUser} type="primary">Submit</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default CreateUser;
