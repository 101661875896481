import AllocationsComponent from "./AllocationsComponent";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { listAllocations } from "../../store/actions/allocations";
import { listProjects } from "../../store/actions/projects";
import { listResources } from "../../store/actions/resources";
import { listUsers } from "../../store/actions/users";
import WeekSelector from "../partials/WeekSelector";
import { getWeekStartString } from "../../helpers/helper_functions";
const Allocations = (props) => {
  const [startDate, setStartDate] = useState(getWeekStartString());
  const allocations = useSelector(
    (state) => state.allocationsReducer.allocations
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listUsers());
    dispatch(listProjects());
  }, []);

  useEffect(() => {
    dispatch(listResources(startDate));
    dispatch(listAllocations(startDate));
  }, [startDate]);

  return (
    <>
      <WeekSelector action={setStartDate} />
      <AllocationsComponent data={allocations} />
    </>
  );
};

export default Allocations;
