import axios from "axios";
import {
  ADD_ALLOCATION,
  DELETE_ALLOCATION,
  EDIT_ALLOCATION,
  GET_ALLOCATIONS,
  GET_SINGLE_ALLOCATION,
} from "./actionTypes";
import { ALLOCATIONS_ENDPOINT } from "../../helpers/endpoints";
import { openNotificationWithIcon } from "../../helpers/helper_functions";
import { HOST } from "../../helpers/constants";

export const getAllocations = (allocations) => ({
  type: GET_ALLOCATIONS,
  payload: allocations,
});

export const getSingleAllocation = (allocation) => ({
  type: GET_SINGLE_ALLOCATION,
  payload: allocation,
});

export const editAllocation = (allocation) => ({
  type: EDIT_ALLOCATION,
  payload: allocation,
});

export const deleteAllocation = (id) => ({
  type: DELETE_ALLOCATION,
  payload: id,
});

export const addAllocation = (allocation) => ({
  type: ADD_ALLOCATION,
  payload: allocation,
});

export const listAllocations = (startDate) => (dispatch) => {
  if (!startDate) {
    throw new Error("missing startDate");
  }
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + ALLOCATIONS_ENDPOINT}`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      params: {
        startDate,
      },
    })
    .then((res) => {
      dispatch(getAllocations(res.data.data));
    })
    .catch((err) => console.log(err));
};

export const getSingleAllocationDetails = (id) => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + ALLOCATIONS_ENDPOINT}/${id}`;

  axios
    .get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      dispatch(getSingleAllocation(res.data));
    })
    .catch((err) => console.log(err));
};

export const updateAllocation = (allocationId, allocationData) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + ALLOCATIONS_ENDPOINT}/${allocationId}`;

  return (dispatch) => {
    axios
      .patch(URL, allocationData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Host: `${HOST}`,
          Accept: "*/*",
        },
      })
      .then((res) => {
        openNotificationWithIcon("success", "Succeeded", "Allocation updated!");
        dispatch(editAllocation(res.data));
        dispatch(listAllocations(allocationData.startDate));
      })
      .catch((err) => {
        console.log(err.response);
        openNotificationWithIcon(
          "error",
          "Update failed",
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Allocation update failed"
        );
      });
  };
};

export const destroyAllocation = (allocationId) => (dispatch) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + ALLOCATIONS_ENDPOINT}/${allocationId}`;

  axios
    .delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Host: `${HOST}`,
        Accept: "*/*",
      },
    })
    .then((res) => {
      dispatch(deleteAllocation(allocationId));
      openNotificationWithIcon("success", "Succeeded", "Allocation deleted");
    })
    .catch((err) => {
      console.log(err);
      openNotificationWithIcon(
        "error",
        "Delete failed",
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "Allocation delete failed"
      );
    });
};

export const createAllocation = (allocationData) => {
  // get token from local storage
  const token = localStorage.getItem("token");
  const URL = `${HOST + ALLOCATIONS_ENDPOINT}`;

  return (dispatch) => {
    axios
      .post(URL, allocationData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Host: `${HOST}`,
          Accept: "*/*",
        },
      })
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Succeeded",
          "New allocation created!"
        );
        dispatch(addAllocation(res.data));
        dispatch(listAllocations(allocationData.startDate));
      })
      .catch((err) => {
        console.log(err.response);
        openNotificationWithIcon(
          "error",
          "Create failed",
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Allocation creation failed"
        );
      });
  };
};
