import ProjectsComponent from "./ProjectsComponent";
import {useSelector, useDispatch} from "react-redux";
import {useEffect} from "react";
import {listProjects} from "../../store/actions/projects";

const Projects = (props) => {
  const projects = useSelector(state => state.projectsReducer.projects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProjects());
  }, []);

  return (
    <>
      <ProjectsComponent data={projects}/>
    </>
  )
}

export default Projects;
