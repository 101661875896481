import ResourcesComponent from "./ResourcesComponent";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { listResources } from "../../store/actions/resources";
import { listAllocations } from "../../store/actions/allocations";
import { listUsers } from "../../store/actions/users";
import { listProjects } from "../../store/actions/projects";
import { getWeekStartString } from "../../helpers/helper_functions";
import WeekSelector from "../partials/WeekSelector";

const Resources = (props) => {
  const [startDate, setStartDate] = useState(getWeekStartString());
  const resources = useSelector((state) => state.resourcesReducer.resources);
  const projects = useSelector((state) => state.projectsReducer.projects);
  const allocations = useSelector(
    (state) => state.allocationsReducer.allocations
  );
  const users = useSelector((state) => state.usersReducer.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listUsers());
    dispatch(listProjects());
  }, []);

  useEffect(() => {
    dispatch(listResources(startDate));
    dispatch(listAllocations(startDate));
  }, [startDate]);
  return (
    <>
      <WeekSelector action={setStartDate} />
      <br />
      <ResourcesComponent
        data={resources}
        allocations={allocations}
        users={users}
        projects={projects}
      />
    </>
  );
};

export default Resources;
